import React, { useContext } from 'react';
import { types, flow } from 'mobx-state-tree';
import { useLocalStore } from 'mobx-react-lite';
import AppStore from './AppStore';

const Store = types.model({
    appStore: AppStore,
    isAuthorized: types.boolean,
    isLoading: types.boolean,
    errorText: types.string,
    username: types.string,
    domain: types.string,
    domains: types.array(types.string),
}).actions(self => ({

    setAuthorized: (value: boolean) => {
        self.isAuthorized = value;
        self.isLoading = false;
    },

    setSessionInfo: (value: any) => {
        const username = value['name'];
        const domain = value['domain'];
        const domains = value['domains'];
        self.username = username;
        self.domain = domain;
        self.domains = domains !== undefined ? domains.sort(function (A: any, B: any) { if (A.is_master) return -1; else if (B.is_master) return 1; else return A.domain.localeCompare(B.domain); })
            .map(function (A: any) { return A.domain })
            .filter((v: string) => { return v !== domain }) : [];

        // to localstorage        
        localStorage.setItem("rDomain", domain);
        localStorage.setItem("rSolution", value['solution']);
        localStorage.setItem("rLogin", value['login']);
        localStorage.setItem("rUserId", value['user_id']);
        localStorage.setItem("rUserName", value['name']);
        localStorage.setItem("rUser", JSON.stringify({
            id: value['user_id'],
            domains: domains,
            login: value['login'],
            name: value['name'],
            name_login: value['name_login'],
            roles: value['roles'],
            tags: value['tags'],
        }));
    },

    login: flow(function* login(state, clearAllSessions: boolean) {
        yield fetch('/rest/v1/iam/sessions/current', { method: 'DELETE' });
        let data: any = { 'domain': state.domain, 'login': state.login, 'pwd': state.pwd };
        if (clearAllSessions) {
            data = { ...data, 'action': 'purge_login' };
        }
        const resp2: any = yield fetch('/rest/v1/iam/sessions', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        if (resp2 && resp2.status >= 200 && resp2.status <= 299) {
            self.isAuthorized = true;
            self.errorText = "";
            // to localstorage
            localStorage.setItem("rDomain", state.domain);
            localStorage.setItem("rLogin", state.login)
            const resp3: any = yield fetch('/rest/v1/iam/sessions/current', {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            });
            let sessionsCurrent: any = {};
            try { sessionsCurrent = yield resp3.json(); } catch { };
            return { isSuccess: true, data: sessionsCurrent };
        }
        else {
            var errBody: any = { 'error_code': "", "error_message": "" };
            try { errBody = yield resp2.json(); } catch { };
            self.isAuthorized = false;
            self.errorText = errBody['error_code'] === undefined ? "Unknown error" : errBody['error_code'] + " - " + errBody['error_message'];
            return { isSuccess: false, errorDetails: errBody };
        }
    }),

    clearOtherSessions: flow(function* logout() {
        const resp: any = yield fetch('/rest/v1/iam/sessions/others', { method: 'DELETE' });
        return resp && resp.status >= 200 && resp.status <= 299;
    }),

    logout: flow(function* logout() {

        // from localStorage
        localStorage.removeItem("rDomain");
        localStorage.removeItem("rSolution");
        localStorage.removeItem("rLogin");
        localStorage.removeItem("rUserId");
        localStorage.removeItem("rUserName");
        localStorage.removeItem("rUser");

        const resp: any = yield fetch('/rest/v1/iam/sessions/current', { method: 'DELETE' });
        const result: boolean = resp && resp.status >= 200 && resp.status <= 299;
        return result
    }),

    changeDomain: flow(function* changeDomain(domain: string) {
        const resp: any = yield fetch('/rest/v1/iam/sessions/current', {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'domain': domain })
        });
        const result = resp.status >= 200 && resp.status <= 299;
        return result;
    })
})).views(self => ({
    getTitle: () => { return self.isAuthorized ? "Authorized" : "Unauthorized"; },
    getAccountName: () => { return self.username + " @ " + self.domain }
}));

export const StoreContext = React.createContext<typeof Store.Type | undefined>(undefined);

export const useStore = () => useContext(StoreContext);

export const createStore = () => Store.create({
    appStore: {},
    isAuthorized: true,
    isLoading: true,
    errorText: "",
    username: "",
    domain: "",
    domains: [],
});

export const StoreProvider: React.FC = ({ children }) => {
    const store = useLocalStore(createStore);
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}